import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Layout from "../components/layout";
import SectionFooter from '../components/sections/section-footer/SectionFooter';
import { theme } from "../utils/theme"
import {Grid} from '@material-ui/core';
import { Link, graphql } from 'gatsby';
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/all'
import { throttle } from 'lodash';

import ProgressBarVert from '../utils/progressBar-step-vert';

import Context from '../context';

gsap.registerPlugin(ScrollToPlugin)



const Container = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.dark};
`

const TitleCond = styled.h5`
  position: fixed;
  left: 15%;
  top: 50px;
  font-size: 4rem;
  /* font-weight: 300; */
  width: 600px;
  letter-spacing: 1px;
  line-height: 1.1;
  color: ${props => props.theme.colors.primary};
  text-align: left;
  /* background-color: ${props => props.theme.colors.dark}; */
  z-index: 80;

  @media ${props => props.theme.breaks.down('md')} {
    top: 100px;
    left: 10%;
  }

  @media ${props => props.theme.breaks.down('sm')} {
    font-size: 2rem;
    top: 80px;
  }
`

const VideoSection = styled.div`
  /* min-height: 100vh;
  padding-top: 50px;
  text-align: center;
  padding-bottom: 350px;
  position: relative; */

  @media ${props => props.theme.breaks.down('sm')} {
    padding-top: 80px;
  }
`

const BarContainer = styled.div`
  height: 400px;
  width: 2rem;
  position: fixed;
  right: 10%;
  top: 30%;

  @media ${props => props.theme.breaks.down('md')} {
    display: none;
  }
`

const FullHeight = styled.div`
  height: 100vh;
  padding-top: 12%;

  @media ${props => props.theme.breaks.down('lg')} {
    padding-top: 15%;
  }

  @media ${props => props.theme.breaks.down('md')} {
    padding-top: 20%;
  }
  @media ${props => props.theme.breaks.down('sm')} {
    height: auto;
    padding-top: 50px;
  }
`

const VidContainer = styled.div`
  width: 60%;
  max-width: 1000px;
  min-height: 400px;
  height: 70%;
  margin: 0 auto;

  iframe {
    width: 100%;
    height: 100%;
  }

  @media ${props => props.theme.breaks.down('sm')} {
    width: 100%;
    min-height: auto;
    height: 280px;
  }
`


const FixedFooter = styled.div`
  height: 100px;
  width: 40%;
  /* min-width: ${props => props.theme.maxWidthMd};
  max-width: 1400px; */
  padding-left: 40px;
  position: fixed;
  background-color: transparent;
  bottom: 1rem;

  @media ${props => props.theme.breaks.down('md')}  {
    bottom: 0;
    padding-left: 0;
  }
`

const Subtitles = styled.h5`
  color: ${props => props.theme.colors.primary};
  width: 60%;
  max-width: 1000px;
  margin: 4rem auto;
  margin-bottom: 0;
  font-weight: 300;
  font-size: 3rem;
  padding-bottom: 100px;

  @media ${props => props.theme.breaks.down('sm')} {
    width: 90%;
    font-size: 2rem;
    margin-top: 2rem;
  }
`

const CelebrandSpeaksPage = ({ data }) => {

  const [counter, setCounter] = useState(Context.vidNum);

  let number = Context.vidNum;

  const scroll = (num, e, count) => {
    if (e.deltaY > 10) {
      if (num < count - 1) {
        gsap.to(window, {scrollTo: `.vid-${num + 1}`, duration: .6})
        Context.vidNum += 1
        setCounter(x => x + 1)
        num = Math.min(num + 1, count - 1)
      } 
    } else if (e.deltaY < -10) {
      if (num > 0) {
        gsap.to(window, {scrollTo: `.vid-${num - 1}`, duration: .6})
        Context.vidNum -= 1
        setCounter(x => x - 1)
        num = Math.max(num - 1, 0)
      } 
    }
    return num;
  }
  
    useEffect(() => {

      const scroller = throttle((e) => {
        number = scroll(number, e, videos.length)
     
    }, 800, {trailing: false})
    
      window.addEventListener("wheel", scroller)
  
      return () => window.removeEventListener("wheel", scroller)
  
    }, [])

    const videos = [
      <iframe width="560" height="315" src="https://www.youtube.com/embed/-sYs1lobtGA?modestbranding=1&rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>,
      <iframe width="560" height="315" src="https://www.youtube.com/embed/AAu6_fQRe-4?modestbranding=1&rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>,
      <iframe width="560" height="315" src="https://www.youtube.com/embed/iTFEMZBvi9s?modestbranding=1&rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>,
      <iframe width="560" height="315" src="https://www.youtube.com/embed/FAi1p3boEAg?modestbranding=1&rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>,
      <iframe width="560" height="315" src="https://www.youtube.com/embed/A_SAbYgFUQI?modestbranding=1&rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    ]

  return (
    
      <Layout color={theme.colors.body} pageTitle="Celebrand Speaks">
      <Container>

        <TitleCond>Ideas worth hearing</TitleCond>
        
        <VideoSection className="Video-sec">
          
          <BarContainer>
            <ProgressBarVert count={videos.length || 0} current={counter} bg="#6E6E6E" color={theme.colors.primary}></ProgressBarVert>
          </BarContainer>

            {videos.length && videos.map((cur, i) => 
            <FullHeight key={`vid${i}`} className={`vid-${i}`} >
              <VidContainer>
                {cur}
              </VidContainer>

              {i + 1 === videos.length ? 
                <Subtitles>
                  English subtitles coming soon
                </Subtitles> 
              : null }

             </FullHeight>
            )}
           
        </VideoSection>

         <FixedFooter>
          <SectionFooter color={theme.colors.body}></SectionFooter>
          
         </FixedFooter>

      </Container>
      </Layout>
    
)
}

export default CelebrandSpeaksPage;
